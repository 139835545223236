import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { css } from '@emotion/core'
import theme from '../theme'

import Header from './header'
import Footer from './Footer'

import './layout.css'
import '../fonts/fonts.css'

const containerStyle = (theme) => css`
	${theme.typography.basic};
	max-width: 1200px;
	margin: 0;
	display: flex;
	flex-direction: column;

	a {
		${theme.typography.link};
	}
`

const Layout = ({ menu, children, excludeHeader, linkToDifferentLanguage, language = 'pl' }) => {
	return (
		<ThemeProvider theme={theme}>
			<div css={containerStyle}>
				{!excludeHeader && <Header menu={menu} linkToDifferentLanguage={linkToDifferentLanguage} language={language} />}
				<div
					style={{
						margin: `0 auto`,
						padding: `0 15px`,
						width: '100%',
					}}
				>
					<main>{children}</main>
				</div>
			</div>
			{!excludeHeader && <Footer language={language} />}
		</ThemeProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
