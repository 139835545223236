import React from 'react'
import ExternalLink from './ExternalLink'
import { css } from '@emotion/core'

import StrefaKulturyLogo from './StrefaKulturyLogo'
import WroclawLogo from './WroclawLogo'
import MkidnLogo from './MkidnLogo'

const headerStyle = (theme) => css`
	${theme.typography.small}
	margin: 0;
	margin-bottom: 5px;
`

const techsStyle = css`
	display: flex;
	justify-content: flex-start;
`

const containerStyle = (theme) => css`
	${theme.typography.basic};
	${theme.typography.small};
	border-top: 1px solid ${theme.colors.blue};
	margin-top: 100px;
	padding: 15px;
	padding-top: 30px;
	margin-bottom: 70px;
	align-self: flex-start;

	a {
		${theme.typography.link};
	}
`

const innerContainerStyle = css`
	max-width: 800px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: left;
`

const footerGrid = (theme) => css`
	display: grid;
	grid-template-columns: 1.4fr 1fr 1.4fr;
	grid-column-gap: 50px;

	${theme.breakpoints.tablet} {
		grid-template-columns: 1fr;
	}
`

const footerLogosContainer = (theme) => css`
	${footerGrid(theme)};
	margin-top: 50px;
`

const footerLogosImagesContainer = (theme) => css`
	${footerGrid(theme)};
	align-items: flex-end;
	justify-items: flex-start;

	${theme.breakpoints.tablet} {
		grid-template-columns: 1fr 1fr;
		margin-top: 20px;
	}
`

const linkList = css`
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
`

const techNameStyle = css`
	margin-right: 15px;
`

const linkStyle = (theme) => css`
	color: ${theme.colors.blue}!important;
	margin-right: 20px;
	text-decoration: none;
`

const techLinkStyle = (theme) => css`
	color: ${theme.colors.blue}!important;
	text-decoration: none;
`

const strefaKulturyContainer = (theme) => css`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: end;

	${theme.breakpoints.tablet} {
		grid-column: 1 / 3;
	}
`

const translations = {
	header: {
		pl: 'Program Sympozjum Wrocław 70/20 współtworzą:',
		en: 'The Symposium Program Wroclaw 70/20 is co-created by:',
	},
	techLabelDesign: {
		pl: 'Projekt graficzny:',
		en: 'Graphic design:',
	},
	techLabelCode: {
		pl: 'Programowanie strony:',
		en: 'Website programming:',
	},
	ministryFinanceNote: {
		pl:
			'Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego pochodzących z Funduszu Promocji Kultury, uzyskanych z dopłat ustanowionych w grach objętych monopolem państwa, zgodnie z art. 80 ust.1 ustawy z dnia 19 listopada 2009 r. o grach hazardowych.',
		en:
			'The project is co-financed by the Ministry of Culture and National Heritage from the Culture Promotion Fund, obtained from subsidies established in games covered by the state monopoly, in accordance with Article 80 (1) of the Act of 19 November 2009 on gambling.',
	},
	cityFinanceNote: {
		pl: 'Organizację Sympozjum Wrocław 70/20 dofinansowano ze środków Gminy Wrocław.',
		en: 'Symposium Wrocław 70/20 is co-financed by the Municipality of Wrocław.',
	},
	websiteAdministrationNote: {
		pl:
			'Administratorem strony internetowej i operatorem dofinansowanego przez Ministerstwo Kultury i Dziedzictwa Narodowego projektu "Sympozjum 70/20" jest Strefa Kultury Wrocław.',
		en:
			'The website administrator and operator of "Symposium 70/20" project co-financed by the Ministry of Culture and National Heritage is Strefa Kultury Wrocław.',
	},
}

const Footer = ({ language }) => {
	return (
		<footer css={containerStyle}>
			<div css={innerContainerStyle}>
				<h3 css={headerStyle}>{translations.header[language]}</h3>
				<ul css={linkList}>
					<li>
						<ExternalLink css={linkStyle} link="https://www.asp.wroc.pl/">
							Akademia Sztuk Pięknych im. Eugeniusza Gepperta
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://bwa.wroc.pl/">
							BWA Wrocław
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.facebook.com/CantiIlluminati/">
							Canti Spazializzati
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="http://arttransparent.org/">
							Fundacja Art Transparent
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://ladnehistorie.pl/">
							Fundacja Ładne Historie
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.entropia.art.pl/">
							Galeria Entropia
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.facebook.com/JESTgaleria/">
							JEST grupa
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://krupagallery.pl/">
							Krupa Gallery
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.facebook.com/kolektywkariatyda/">
							Kolektyw Kariatyda
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.facebook.com/pg/czybadaniaartystyczne/">
							Kolektyw ¿ Czy badania artystyczne ?
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="http://www.ma.wroc.pl/">
							Muzeum Architektury we Wrocławiu
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://mnwr.pl/">
							Muzeum Narodowe we Wrocławiu
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://muzeumpanatadeusza.ossolineum.pl/">
							Muzeum Pana Tadeusza
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://muzeumwspolczesne.pl/">
							Muzeum Współczesne Wrocław
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.openheim.org/">
							OP ENHEIM
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://zoltyparasol.org/">
							Stowarzyszenie Żółty Parasol
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="http://strefakultury.pl/">
							Strefa Kultury Wrocław
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="http://www.tiffcenter.com">
							TIFF Center
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.facebook.com/Why.Quit.2019/">
							Why Quit
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="http://wrocff.com.pl/">
							Wrocławska Fundacja Filmowa
						</ExternalLink>
					</li>
					<li>
						<ExternalLink css={linkStyle} link="https://www.facebook.com/zlotykiosk/">
							ZŁOTY KIOSK
						</ExternalLink>
					</li>
				</ul>
				<div css={techsStyle}>
					<div css={techNameStyle}>
						<strong>{translations.techLabelDesign[language]} </strong> Marian Misiak /{' '}
						<ExternalLink css={techLinkStyle} link="https://threedotstype.com/">
							threedotstype.com
						</ExternalLink>
					</div>
					<div css={techNameStyle}>
						<strong>{translations.techLabelCode[language]} </strong> Konrad Kruk
					</div>
				</div>
				<div css={footerLogosContainer}>
					<div>{translations.ministryFinanceNote[language]}</div>
					<div>{translations.cityFinanceNote[language]}</div>
					<div>{translations.websiteAdministrationNote[language]}</div>
				</div>
				<div css={footerLogosImagesContainer}>
					<MkidnLogo />
					<WroclawLogo language={language} />
					<div css={strefaKulturyContainer}>
						<StrefaKulturyLogo language={language} />
						<div>
							Strefa Kultury Wrocław
							<br />
							ul. Świdnicka 8B
							<br />
							50-067 Wrocław
							<br />
							NIP: 899-273-65-81
							<br />
							tel. +48 (71) 712-75-75
							<br />
							sekretariat.barbara@strefakultury.pl
							<br />
							<ExternalLink link="https://strefakultury.pl" css={techLinkStyle}>
								strefakultury.pl
							</ExternalLink>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
