import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'

import ExternalLink from './ExternalLink'

const linkStyle = css`
	display: block;
	display: flex;
`

const MkidnLogo = () => {
	const data = useStaticQuery(graphql`
		query {
			mkidnLogo: file(relativePath: { eq: "mkidn-pl.png" }) {
				childImageSharp {
					fixed(height: 40) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	return (
		<ExternalLink css={linkStyle} link="https://www.gov.pl/web/kultura">
			<Img fixed={data.mkidnLogo.childImageSharp.fixed} alt="Ministerstwo Kultury i Dziedzictwa Narodowego" />
		</ExternalLink>
	)
}

export default MkidnLogo
