import { Link, useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { css } from '@emotion/core'
import logo from '../images/sympozjum-7020-logo.svg'
import menuIcon from '../images/menu-icon.svg'
import ExternalLink from './ExternalLink'
import * as commonStyles from '../commonStyles'

const logoStyle = (theme) => css`
	height: 120px;

	${theme.breakpoints.mobile} {
		height: 90px;
	}
`

const logoAndMenuContainer = css`
	display: flex;
	align-items: flex-start;
	margin-left: -15px;
`

const logoLinkStyle = css`
	display: block;
	flex-grow: 1;
`

const hamburgerMenuButtonStyle = (theme) => css`
	${commonStyles.resetButton};
	display: none;
	padding: 10px;
	position: relative;
	z-index: 2;

	${theme.breakpoints.mobile} {
		display: block;
	}
`

const nonLogoTopbarElementStyle = css``

const headerTitleStyle = (theme) => css`
	margin: 0;
	${nonLogoTopbarElementStyle};
	${theme.typography.basic};
	${theme.typography.bold};
	margin-top: 10px;

	${theme.breakpoints.mobile} {
		color: ${theme.colors.black};
	}
`

const socialMenuLabel = (theme) => css`
	${theme.typography.bold};
	display: block;
`

const headerStyle = (show) => (theme) => css`
	margin-bottom: 160px;
	padding: 0 15px;

	${theme.breakpoints.mobile} {
		margin-bottom: 40px;

		${show && {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: theme.colors.blue,
			color: theme.colors.white,
			zIndex: 1,
			marginBottom: 0,
			overflowY: 'auto',
		}}
	}
`

const topBarStyle = (theme) => css`
	display: grid;
	grid-template-columns: 3fr 5fr 2fr;
	column-gap: 15px;

	${theme.breakpoints.tablet} {
		grid-template-columns: 1fr 2fr;
	}

	${theme.breakpoints.mobile} {
		grid-template-columns: 1fr;
	}
`

const arrowBottomStyle = (theme) => css`
	display: inline-block;
	${theme.breakpoints.mobile} {
		display: none;
	}
`

const navigationStyle = (show) => (theme) => css`
	display: flex;
	justify-content: center;
	padding-bottom: 40px;

	${theme.breakpoints.mobile} {
		display: ${show ? 'flex' : 'none'};
		padding-bottom: 0px;
	}
`

const socialMenuStyle = (theme) => css`
	margin: 0;
	padding: 0;
	${nonLogoTopbarElementStyle};
	list-style-type: none;
	margin-top: 10px;

	a {
		text-decoration: none;
		color: inherit;
	}

	${theme.breakpoints.mobile} {
		display: none;
	}
`

const currentLanguageStyle = css`
	font-weight: bold;
`

const currentElementStyle = css`
	display: block!important;
`

const mobileSocialMenuStyle = (theme) => css`
	${socialMenuStyle(theme)};
	display: none;
	${theme.typography.big};
	margin-top: 20px !important;
	word-break: break-word;

	${theme.breakpoints.mobile} {
		display: block;
	}

	li {
		margin-left: 0 !important;
	}
`

const menuStyle = (theme) => css`
	margin: 0;
	padding: 0;
	margin-left: 150px;
	list-style-type: none;
	display: flex;
	${theme.typography.navigation};

	& > li {
		margin-right: 50px;

		${theme.breakpoints.mobile} {
			margin-right: 0;
		}
	}

	& > li:last-child {
		margin-right: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	ul {
		list-style-type: none;
		
		margin: 0;
		padding: 0;
		position: absolute;

		li {
			display: none;
		}

		${theme.breakpoints.mobile} {
			li {
				display: block;
			}
		}
	}

	li:hover {
		.arrow {
			transform: rotate(180deg);
		}
		ul li {
			display: block;
			margin: 0;
			padding: 0;
		}
	}

	${theme.breakpoints.mobile} {
		flex-direction: column;
		margin-left: 0px;

		ul {
			position: relative;
		}

		li li {
			margin-left: 35px;
		}
	}
`

const getMenuLabelStyle = (isActive) => (theme) => {
	return css`
		${isActive && theme.typography.bold};
		white-space: nowrap;

		${theme.breakpoints.mobile} {
			${theme.typography.bold};
			display: inline-block;
			margin-top: 20px;
		}
	`
}

const menu2020ItemStyle = css`
	width: 330px;
`

const getLabel = (node, language) => {
	return language === 'pl' ? node.frontmatter.menu_label : node.frontmatter.en_menu_label
}

const getSlug = (node, language) => {
	const slug = language === 'pl' ? node.fields.slug : node.fields.enSlug
	return slug
}

const translations = {
	Sympozjum: 'Symposium',
	'Odwiedź nasze kanały:': 'Visit our channels:',
	'Napisz do nas:': 'Write to us:',
	'Rok 1970': 'Year 1970',
	'Rok 2020': 'Year 2020',
	ENG: 'PL',
}

const getText = (phrase, language) => {
	if (language === 'en') {
		return translations[phrase]
	}
	return phrase
}

const Header = ({ menu, linkToDifferentLanguage, language }) => {
	const [showMobileMenu, setShowMobileMenu] = React.useState(false)
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			allMarkdownRemark(
				sort: { fields: [frontmatter___menu_order], order: ASC }
				filter: { frontmatter: { menu: { in: ["1970", "2020"] } } }
			) {
				edges {
					node {
						frontmatter {
							menu_label
							en_menu_label
							menu
						}
						fields {
							slug
							enSlug
						}
					}
				}
			}
		}
	`)
	const {
		allMarkdownRemark: { edges },
	} = data
	let menuItems1970 = [...edges.filter((edge) => edge.node.frontmatter.menu === '1970')]
	let menuItems2020 = [...edges.filter((edge) => edge.node.frontmatter.menu === '2020')]
	const creatorsItem = {
		node: {
			fields: {
				slug: '/tworcy',
				enSlug: '/en/creators',
			},
			frontmatter: {
				menu_label: 'Twórcy',
				en_menu_label: 'Creators',
			},
		},
	}
	const repositoryItem = {
		node: {
			fields: {
				slug: '/repozytorium',
				enSlug: '/en/repository',
			},
			frontmatter: {
				menu_label: 'Repozytorium',
				en_menu_label: 'Repository',
			},
		},
	}
	menuItems2020 = [repositoryItem, ...menuItems2020]
	if (typeof window !== 'undefined' && window.localStorage.getItem('show_repo')) {
		menuItems1970 = [creatorsItem, ...menuItems1970]
	}
	let activeMenu2020 = menu === '2020'
	let activeMenu1970 = menu === '1970'
	let activeMenu2020Index = null
	let activeMenu1970Index = null
	if (typeof window !== 'undefined' && window.location && window.location.pathname) {
		let parsedPath = window.location.pathname.replace(/\/$/, "")
		if (parsedPath.indexOf('/repozytorium') !== -1) {
			parsedPath = '/repozytorium'
		} 
		activeMenu2020Index = menuItems2020.findIndex(
			(edge) =>
				edge.node.fields.slug === parsedPath || edge.node.fields.enSlug === window.location.pathname,
		)
		activeMenu1970Index = menuItems1970.findIndex(
			(edge) =>
				edge.node.fields.slug === parsedPath || edge.node.fields.enSlug === window.location.pathname,
		)
	}
	return (
		<header css={headerStyle(showMobileMenu)}>
			<div css={topBarStyle}>
				<div css={logoAndMenuContainer}>
					<Link to={language === 'en' ? '/en' : '/'} css={logoLinkStyle}>
						<img css={logoStyle} src={logo} alt="Sympozjum 7020 logo" />
					</Link>
					<button onClick={() => setShowMobileMenu(!showMobileMenu)} css={hamburgerMenuButtonStyle}>
						<img src={menuIcon} alt="Open menu" />
					</button>
				</div>
				<h1 css={headerTitleStyle}>
					{getText('Sympozjum', language)}
					<br />
					Wrocław 70/20
				</h1>
				<ul css={socialMenuStyle}>
					<li>
						{language === 'en' ? (
							<Link to={linkToDifferentLanguage || '/pl'}>PL</Link>
						) : (
							<span css={currentLanguageStyle}>PL</span>
						)}{' '}
						{language === 'pl' ? (
							<Link to={linkToDifferentLanguage || '/en'}>ENG</Link>
						) : (
							<span css={currentLanguageStyle}>ENG</span>
						)}
					</li>
					<li>
						<span css={socialMenuLabel}>{getText('Odwiedź nasze kanały:', language)}</span>
						<ExternalLink link="https://www.facebook.com/Sympozjum7020/">Facebook</ExternalLink>{' '}
						<ExternalLink link="https://instagram.com/Sympozjum7020">Instagram</ExternalLink>{' '}
						<ExternalLink link="https://anchor.fm/sympozjum7020">Anchor</ExternalLink>
					</li>
					<li>
						<span css={socialMenuLabel}>{getText('Napisz do nas:', language)}</span>{' '}
						<a href="mailto:info@sympozjum7020.pl">info@sympozjum7020.pl</a>
					</li>
				</ul>
			</div>
			<nav css={navigationStyle(showMobileMenu)}>
				<ul css={menuStyle}>
					<li>
						<span css={getMenuLabelStyle(activeMenu1970)}>
							{getText('Rok 1970', language)}{' '}
							<span className="arrow" css={arrowBottomStyle}>
								▼
							</span>
						</span>
						
						<ul>
						{activeMenu1970Index !== null && (
							<li css={currentElementStyle}>
								{menuItems1970[activeMenu1970Index] && getLabel(menuItems1970[activeMenu1970Index].node, language)}
							</li>
						)}
							{menuItems1970.map((item, index) => {
								if (activeMenu1970Index === index) {
									return null
								}
								return (
									<li key={item.node.fields.slug}>
										<Link to={getSlug(item.node, language)}>{getLabel(item.node, language)}</Link>
									</li>
								)
							})}
						</ul>
					</li>
					<li css={menu2020ItemStyle}>
						<span css={getMenuLabelStyle(activeMenu2020)}>
							{getText('Rok 2020', language)}{' '}
							<span className="arrow" css={arrowBottomStyle}>
								▼
							</span>
						</span>
						
						<ul>
						{activeMenu2020Index !== null && (
							<li css={currentElementStyle}>
								{menuItems2020[activeMenu2020Index] && getLabel(menuItems2020[activeMenu2020Index].node, language)}
							</li>
						)}
							{menuItems2020.map((item, index) => {
								if (activeMenu2020Index === index) {
									return null
								}
								return (
									<li key={item.node.fields.slug}>
										<Link to={getSlug(item.node, language)}>{getLabel(item.node, language)}</Link>
									</li>
								)
							})}
						</ul>
					</li>
					<li>
						<ul css={mobileSocialMenuStyle}>
							<li>
								{language === 'en' ? (
									<Link to={linkToDifferentLanguage || '/pl'}>PL</Link>
								) : (
									<span css={currentLanguageStyle}>PL</span>
								)}{' '}
								{language === 'pl' ? (
									<Link to={linkToDifferentLanguage || '/en'}>ENG</Link>
								) : (
									<span css={currentLanguageStyle}>ENG</span>
								)}
							</li>
							<li>
								<span css={socialMenuLabel}>{getText('Odwiedź nasze kanały:', language)}</span>
								<ExternalLink link="https://www.facebook.com/Sympozjum7020/">Facebook</ExternalLink>{' '}
								<ExternalLink link="https://instagram.com/Sympozjum7020">Instagram</ExternalLink>{' '}
								<ExternalLink link="https://anchor.fm/sympozjum7020">Anchor</ExternalLink>
							</li>
							<li>
								<span css={socialMenuLabel}>{getText('Napisz do nas:', language)}</span>{' '}
								<a href="mailto:info@sympozjum7020.pl">info@sympozjum7020.pl</a>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</header>
	)
}
export default Header
