import * as React from 'react'

const ExternalLink = ({ children, link, ...props }) => {
	return (
		<a href={link} rel="nofollow noopener noreferrer" target="_blank" {...props}>
			{children}
		</a>
	)
}

export default ExternalLink
