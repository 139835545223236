import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'

import ExternalLink from './ExternalLink'

const linkStyle = css`
	display: block;
	display: flex;
`

const WroclawLogo = () => {
	const data = useStaticQuery(graphql`
		query {
			pl: file(relativePath: { eq: "wroclaw-pl.png" }) {
				childImageSharp {
					fixed(height: 40) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	return (
		<ExternalLink css={linkStyle} link="https://www.wroclaw.pl/">
			<Img fixed={data.pl.childImageSharp.fixed} alt="Wrocław Miasto Spotkań" />
		</ExternalLink>
	)
}

export default WroclawLogo
