import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'

import ExternalLink from './ExternalLink'

const linkStyle = (theme) => css`
	display: block;
	display: flex;

	${theme.breakpoints.tablet} {
		justify-content: flex-start;
		margin-right: 20px;
	}
`

const StrefaKulturyLogo = ({ language }) => {
	const data = useStaticQuery(graphql`
		query {
			pl: file(relativePath: { eq: "strefakultury-pl.png" }) {
				childImageSharp {
					fixed(height: 40) {
						...GatsbyImageSharpFixed
					}
				}
			}
			en: file(relativePath: { eq: "strefakultury-en.png" }) {
				childImageSharp {
					fixed(height: 40) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	return (
		<ExternalLink css={linkStyle} link="https://strefakultury.pl">
			<Img fixed={data[language].childImageSharp.fixed} alt="Strefa Kultury Wrocław" />
		</ExternalLink>
	)
}

export default StrefaKulturyLogo
