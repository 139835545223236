const colors = {
	black: '#000',
	blue: 'rgb(0, 79, 159)',
	red: 'rgb(227, 6, 19)',
	white: '#fff',
	green: 'rgb(0, 102, 59)',
	ecru: 'rgb(203, 187, 160)',
}

const breakpoints = {
	tablet: '@media (max-width: 770px)',
	mobile: '@media (max-width: 550px)',
}

const grid = {
	default: {
		display: 'grid',
		gridTemplateColumns: '1fr minmax(0, 7fr) 2fr',
		columnGap: '15px',
	},
}

const typography = {
	basic: {
		fontFamily: 'Atlas Grotesk Web',
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontFeatureSettings: `"liga", "kern"`,
		color: colors.blue,
		fontSize: '15px',
		lineHeight: '1.2em',
	},
	navigation: {
		fontSize: '33px',
		lineHeight: '39px',
	},
	big: {
		fontSize: '33px',
		lineHeight: '39px',
	},
	bigVariant: {
		fontSize: '28px',
		lineHeight: '34px',
	},
	header: {
		fontSize: '33px',
		lineHeight: '39px',
		fontWeight: 'bold',
		color: colors.blue,
	},
	link: {
		color: colors.black,
	},
	bold: {
		fontWeight: 'bold',
	},
	italic: {
		fontStyle: 'italic',
	},
	sidebar: {
		color: colors.ecru,
	},
	imageTitle: {
		color: colors.ecru,
	},
	small: {
		fontSize: '9px',
		lineHeight: '12px'
	}
}

const theme = {
	colors,
	typography,
	breakpoints,
	grid,
}

export default theme
